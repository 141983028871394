import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../hooks/useMediaQuery"

const Quickbase = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "24px 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Strategic Quickbase Partners for a Consultative Approach
            </h2>
            <p
              className="text-center mx-auto px-sm-2"
              style={{ maxWidth: "825px" }}
            >
              We explore your business challenges, compare industry benchmarks,
              and work with you to build the robust solutions you envision.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Clear Reporting
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/02088a807e0ade62d18d6613a2e5fa5746e10c54-1600x771.webp"
                      alt="Clear Reporting"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Clear Reporting</h3>
                    <p className="my-4 text-left-mobile">
                      We design clear dashboards for easy reporting your teams
                      can use and share to make informed decisions in real-time.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Extend Functionality
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Extend Functionality</h3>
                    <p className="my-4 text-left-mobile">
                      We help businesses extend the functionality of enterprise
                      applications like Workday with Quickbase, providing more
                      customization for critical systems.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/80201fcd617a12cfe2585a763fb434491cf47785-803x397.webp"
                      alt="Extend Functionality"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mx-auto text-center"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Complex Integrations
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/3e79bd3c28cbf7f05d4e4798f45c8baeccd9b64a-1528x878.webp"
                      alt=" Complex Integrations"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Complex Integrations</h3>
                    <p className="my-4 text-left-mobile">
                      We specialize in integrating CRMs, ERPs, and custom
                      software with Quickbase, so your data flows without gaps
                      or errors.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Complete Access
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Complete Access</h3>
                    <p className="my-4 text-left-mobile">
                      You own your Quickbase applications. Always. Retain full
                      ownership and access of your code—so you’re never locked
                      into working with us.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/092059e2860225bc82d16834cd9aa0c471074d87-1279x853.jpg"
                      alt="Complete Access"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab5"
              />
              <label
                for="tab5"
                class="tabs__label mx-auto text-center"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Staff Augmentation
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/597f931027655d35e53c1a9bb4a7d7e6c5ab08d3-1279x855.jpg"
                      alt=" Staff Augmentation"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Staff Augmentation</h3>
                    <p className="my-4 text-left-mobile">
                      Stretched thin? Lacking deep Quickbase expertise? Get
                      direct access to Quickbase experts—without the hassle of
                      hiring full-time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Clear Reporting</h3>
                    <p className="my-4 ">
                      We design clear dashboards for easy reporting your teams
                      can use and share to make informed decisions in real-time.
                    </p>

                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/technology-consulting/"
                    >
                      Start Your Analysis
                      <span className="d-none">
                        This is a redirection to quandary-build
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    {/* <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/02088a807e0ade62d18d6613a2e5fa5746e10c54-1600x771.webp"
                      alt="Clear Reporting
"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Extend Functionality</h3>
                    <p className="my-4 ">
                      We help businesses extend the functionality of enterprise
                      applications like Workday with Quickbase, providing more
                      customization for critical systems.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/80201fcd617a12cfe2585a763fb434491cf47785-803x397.webp"
                      alt="Extend Functionality"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Complex Integrations</h3>
                    <p className="my-4 ">
                      We specialize in integrating CRMs, ERPs, and custom
                      software with Quickbase, so your data flows without gaps
                      or errors.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/citizen-development-support/"
                    >
                      Find Your AI Gaps
                      <span className="d-none">
                        This is a redirection to Procurement Automation
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/3e79bd3c28cbf7f05d4e4798f45c8baeccd9b64a-1528x878.webp"
                      alt="Complex Integrations"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Complete Access</h3>
                    <p className="my-4 ">
                      You own your Quickbase applications. Always. Retain full
                      ownership and access of your code—so you’re never locked
                      into working with us.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/092059e2860225bc82d16834cd9aa0c471074d87-1279x853.jpg"
                      alt="Complete Access"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Staff Augmentation</h3>
                    <p className="my-4 ">
                      Stretched thin? Lacking deep Quickbase expertise? Get
                      direct access to Quickbase experts—without the hassle of
                      hiring full-time.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/597f931027655d35e53c1a9bb4a7d7e6c5ab08d3-1279x855.jpg"
                      alt="Staff Augmentation"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default Quickbase
